<script setup lang="ts">
import { type ISbStoryData, useStoryblokApi } from '@storyblok/vue'

const route = useRoute()

let slug = 'home'

// Here we are getting the path as a URL parameter
if (route.params.slug.length) {
  slug = (route.params.slug as string[]).join('/')
}

const storyblokApi = useStoryblokApi()
const story = ref<ISbStoryData>()

const relations = [
  'insights-collection.insights',
  'insights-list.insights',
  'projects-list.projects',
  'our-projects.project_list',
  'project-blok.project_list',
  'clients.client_list',
  'client-voices.client_list',
  'rounded-card.sales_member',
  'crew-member.client_list',
  'crew-member.projects',
  'crew-member.insights',
  'crew-list.client_list',
  'contact-image-list.contact_member',
].join(',')

try {
  const { data } = await storyblokApi.get('cdn/stories/' + slug, {
    version: getStoryblokVersion(),
    language: 'default',
    fallback_lang: 'default',
    resolve_links: 'url',
    resolve_relations: relations,
  })

  story.value = data.story
} catch (error) {
  /**
   * When you need to create a special 404 page, make sure it's first
   * configured in Storyblok and change the route below if needed.
   */
  const { data } = await storyblokApi.get('cdn/stories/site-config/404', {
    version: getStoryblokVersion(),
  })

  story.value = data.story
}

onMounted(() => {
  if (story && story.value && story.value.id) {
    useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory), {
      preventClicks: true,
    })
  }
})
</script>

<template>
  <div v-if="story" v-editable="story.content">
    <StoryblokComponent :blok="story.content" :uuid="story.uuid" />
  </div>
</template>
